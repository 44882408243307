<template>
  <div>
    <b-card
      no-body
      class="coupon-card"
      style-class=""
    >
      <b-card-header>
        <div class="filter-tab w-100 mb-2">
          <div
            v-for="item in filterTabs"
            :key="item.id"
            :class="['filter-tab__item', {'active': filterSelected == item.id}]"
            @click="filterSelected = item.id"
          >
            {{ $t(item.label) }} ({{ commentsCount(item.id) }})
          </div>
        </div>

        <div
          class="d-flex flex-wrap justify-content-between align-items-center w-100"
          style="gap: 8px;"
        >
          <b-form-group class="mb-0">
            <div class="d-flex align-items-center">
              <label class="mr-1">{{ $t('Search') }}</label>
              <b-form-input
                v-model="searchTerm"
                :placeholder="$t('Search')"
                type="text"
                class="d-inline-block"
                style="width: 220px;"
              />
            </div>
          </b-form-group>
          <b-button
            variant="primary"
            type="button"
            class="ml-2"
            to="add"
          >
            <feather-icon
              icon="PlusIcon"
              class="mr-50"
            />
            {{ $t('Create new') }}
          </b-button>
        </div>
      </b-card-header>
      <b-card-body class="p-0">
        <!-- table -->
        <vue-good-table
          ref="promotion-products"
          class="custom-good-table custom-good-table-ctrl-border"
          style-class="vgt-table"
          :columns="fields"
          :rows="filterItems"
          :search-options="{
            enabled: true,
            externalQuery: searchTerm }"
          :pagination-options="{
            enabled: true,
            perPage:pageLength
          }"
        >
          <!-- Table: Head -->
          <template
            slot="table-column"
            slot-scope="props"
          >
            <span>
              {{ $t(props.column.label) }}
            </span>
          </template>

          <!-- Table: Rows -->
          <template
            slot="table-row"
            slot-scope="props"
          >
            <!-- Column: Name -->
            <span
              v-if="props.column.field === 'fullname'"
              class="d-flex align-items-center"
            >
              <b-avatar
                :src="props.row.avatar"
                size="lg"
                class="mr-1"
                square
              />
              <span class="d-block text-nowrap">{{ props.row.fullname }}</span>
            </span>

            <!-- Column: Position -->
            <span
              v-else-if="props.column.field === 'rating'"
              class="d-block text-center"
            >
              <b-form-rating
                v-model="props.row.rating"
                no-border
                variant="warning"
                readonly
                inline
              />
            </span>

            <!-- Column: Status -->
            <span
              v-else-if="props.column.field === 'status'"
              class="d-block text-center"
            >
              <b-badge
                :variant="statusVariant(props.row.status)"
                style="width: 120px;"
              >
                {{ $t(filterTabs.find(item => item.id === props.row.status).label) }}
              </b-badge>
            </span>

            <!-- Column: Content -->
            <span
              v-else-if="props.column.field === 'content'"
              class="d-block"
              style="width: 240px;"
            >
              {{ props.row.content }}
            </span>

            <!-- Column: Actions -->
            <span
              v-else-if="props.column.field === 'actions'"
              class="d-flex"
            >
              <b-button
                v-b-modal.modal-detail-comment
                variant="flat-primary"
                class="btn-icon rounded-circle"
                @click="getDataDetail(props.row.id)"
              >
                <feather-icon icon="EyeIcon" />
              </b-button>
              <b-button
                variant="flat-success"
                :class="['btn-icon rounded-circle', {'disabled': props.row.status === 2}]"
                @click="handleUpdate(props.row.id, 2)"
              >
                <feather-icon icon="CheckIcon" />
              </b-button>
              <b-button
                variant="flat-danger"
                :class="['text-danger btn-icon rounded-circle', {'disabled': props.row.status === 3}]"
                @click="handleUpdate(props.row.id, 3)"
              >
                <feather-icon icon="SlashIcon" />
              </b-button>
            </span>

            <!-- Column: Common -->
            <span v-else>
              {{ props.formattedRow[props.column.field] }}
            </span>
          </template>

          <!-- pagination -->
          <template
            slot="pagination-bottom"
            slot-scope="props"
          >
            <div class="d-flex justify-content-between flex-wrap p-2">
              <div class="d-flex align-items-center mb-0 mt-1">
                <span class="text-nowrap ">
                  Showing 1 to
                </span>
                <b-form-select
                  v-model="pageLength"
                  :options="['3','5','10']"
                  class="mx-1"
                  @input="(value)=>props.perPageChanged({currentPerPage:value})"
                />
                <span class="text-nowrap"> of {{ props.total }} entries </span>
              </div>
              <div>
                <b-pagination
                  :value="1"
                  :total-rows="props.total"
                  :per-page="pageLength"
                  first-number
                  last-number
                  align="right"
                  prev-class="prev-item"
                  next-class="next-item"
                  class="mt-1 mb-0"
                  @input="(value)=>props.pageChanged({currentPage:value})"
                >
                  <template #prev-text>
                    <feather-icon
                      icon="ChevronLeftIcon"
                      size="18"
                    />
                  </template>
                  <template #next-text>
                    <feather-icon
                      icon="ChevronRightIcon"
                      size="18"
                    />
                  </template>
                </b-pagination>
              </div>
            </div>
          </template>
        </vue-good-table>
      </b-card-body>
    </b-card>

    <b-modal
      id="modal-detail-comment"
      centered
      size="lg"
      modal-class="modal-primary"
      :title="$t('Review from customer')"
      :hide-footer="true"
    >
      <b-row>
        <b-col
          md="6"
          class="d-flex flex-column"
          style="gap: 16px;"
        >
          <div class="d-flex align-items-center">
            <b-avatar
              square
              size="lg"
              :src="model.avatar_customers"
              class="mr-1"
            />
            <strong>{{ model.fullname }}</strong>
          </div>
          <div class="d-flex align-items-center">
            <strong class="mr-1">{{ $t('Rating') }}:</strong>
            <b-form-rating
              v-model="model.rating"
              no-border
              variant="warning"
              readonly
              inline
              class="p-0"
            />
          </div>
          <div class="d-flex align-items-center">
            <strong class="text-nowrap mr-1">{{ $t('Created at') }}:</strong>
            {{ model.created_at }}
          </div>
        </b-col>
        <b-col
          md="6"
          class="d-flex flex-column"
          style="gap: 16px;"
        >
          <b-avatar
            square
            size="lg"
            :src="model.avatar_product"
          />
          <div class="d-flex align-items-center">
            <strong class="text-nowrap mr-1">{{ $t('Order ID') }}:</strong>
            <router-link
              :to="`/order/edit/${model.order_item_id_code}`"
            >
              {{ model.order_id }}
            </router-link>
          </div>
          <div class="d-flex align-items-center">
            <strong class="mr-1">{{ $t('Product') }}:</strong>
            <span>{{ model.product_name }}</span>
          </div>
        </b-col>
      </b-row>
      <b-alert
        show
        variant="light"
        class="p-2 mt-2"
      >
        <h4>{{ model.fullname }}</h4>
        <div>{{ model.content }}</div>
      </b-alert>
    </b-modal>
  </div>
</template>
<script>
/* eslint-disable operator-linebreak */
import {
  // BTabs,
  // BTab,
  // BTable,
  BRow,
  BCol,
  BFormGroup,
  BFormSelect,
  BPagination,
  BFormRating,
  BAvatar,
  // BLink,
  // BFormTextarea,
  BButton,
  // BFormFile,
  BBadge,
  // BImgLazy,
  // BAspect,
  VBTooltip,
  BFormInput,
  BCard,
  BCardHeader,
  BCardBody,
  BAlert,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import Request from '@/plugins/service/index'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import general from '@/mixins/index'
// import TableList from './tableList.vue'

export default {
  components: {
    // BTabs,
    // BTab,
    // BTable,
    BRow,
    BCol,
    BFormGroup,
    BFormSelect,
    BPagination,
    BFormRating,
    BAvatar,
    // BLink,
    // BFormTextarea,
    BButton,
    // BFormFile,
    // TableList,
    BBadge,
    // BImgLazy,
    // BAspect,
    BFormInput,
    BCard,
    BCardHeader,
    BCardBody,
    BAlert,
    VueGoodTable,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  mixins: [general],
  data() {
    return {
      isWriteComment: false,
      isEditComment: false,
      paging: true,
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      filter: null,
      filterOn: [],
      per_page: 10,
      pageOptions: [
        5,
        10,
        20,
        this.per_page !== 5 && this.per_page !== 10 && this.per_page !== 20
          ? this.per_page
          : 30,
      ],
      perPage: this.per_page,
      totalRows: 100,
      currentPage: 1,
      currentLang: JSON.parse(localStorage.getItem('siteID')).language,
      // fields: [
      //   { key: 'avatar', label: 'Avatar', sortable: false },
      //   { key: 'fullname', label: 'Full name', sortable: false },
      //   { key: 'rating', label: 'Rating', sortable: true },
      //   { key: 'content', label: 'Comment', sortable: false },
      //   { key: 'customer_id', label: 'Customer', sortable: false },
      //   { key: 'status', label: 'Status', sortable: false },
      //   { key: 'created_at', label: 'Submitted on', sortable: true },
      //   { key: 'more_btn', label: 'More', sortable: false },
      //   // { key: 'more_btn', label: 'More', sortable: false },
      // ],
      fields: [
        { field: 'fullname', label: 'Full name', sortable: true },
        { field: 'rating', label: 'Rating', sortable: false },
        { field: 'content', label: 'Content', sortable: false },
        { field: 'status', label: 'Status', sortable: false },
        { field: 'created_at', label: 'Submitted on', sortable: false },
        { field: 'actions', label: 'Actions', sortable: false },
      ],
      items: [],
      tabList: [
        {
          id: 0,
          name: 'All',
          count: 0,
          note: 'All',
          items: [],
        },
        {
          id: 1,
          name: 'Pendding',
          count: 0,
          note: 'Đang xử lý',
          items: [],
        },
        {
          id: 2,
          name: 'Approved',
          count: 0,
          note: 'Duyệt',
          items: [],
        },
        {
          id: 3,
          name: 'Unapprove',
          count: 0,
          note: 'Unapprove',
          items: [],
        },
      ],
      model: {
        id_code: null,
        fullname: null,
        rating: null,
        created_at: null,
        order_id: null,
        order_item_id_code: null,
        product_name: null,
        avatar_customers: null,
        avatar_product: null,
        content: null,
        list_thumbnail: [],
      },
      languageList: null,
      pageLength: 10,
      searchTerm: '',
      filterSelected: 'all',
      filterTabs: [
        {
          id: 'all',
          label: 'All',
        },
        {
          id: 1,
          label: 'Pending',
        },
        {
          id: 2,
          label: 'Approved',
        },
        {
          id: 3,
          label: 'Unapproved',
        },
      ],
    }
  },
  computed: {
    statusVariant() {
      const statusColor = {
        /* eslint-disable key-spacing */
        1     : 'light-warning',
        2     : 'light-success',
        3     : 'light-danger',
        /* eslint-enable key-spacing */
      }

      return status => statusColor[status]
    },
    filterItems() {
      if (this.filterSelected === 'all') return this.items
      return this.items.filter(item => item.status === this.filterSelected)
    },
  },
  mounted() {
    this.loadList()
    this.loadLanguages()
  },
  methods: {
    changeTab() {
      this.$router.replace({})
    },
    getActive(id) {
      if (id === 1) {
        this.isWriteComment = true
        this.isEditComment = false
      } else if (id === 2) {
        this.isEditComment = true
        this.isWriteComment = false
      } else {
        this.isEditComment = false
        this.isWriteComment = false
      }
    },
    async loadList() {
      const siteId = JSON.parse(localStorage.getItem('siteID')).id
      try {
        const res = await Request.get(
          this.$http,
          `${process.env.VUE_APP_API_URL}/comments?site_id=${siteId}&sort[]=created_at,desc`,
        )
        if (res.status === 200) {
          if (res.data.status) {
            const mapData = res.data.data.items
            this.items = mapData
            // All
            this.tabList[0].items = await this.getDataTranslation(mapData, true)
            this.tabList[0].count = mapData.length
            this.totalRows = this.tabList[0].items.length
            // pendding
            this.tabList[1].items = await this.getDataTranslation(mapData.filter(x => x.status === 1), true)
            this.tabList[1].count = mapData.filter(x => x.status === 1).length
            this.totalRows = mapData.filter(x => x.status === 1).length
            // accept
            this.tabList[2].items = await this.getDataTranslation(mapData.filter(x => x.status === 2), true)
            this.tabList[2].count = mapData.filter(x => x.status === 2).length
            this.totalRows = mapData.filter(x => x.status === 2).length
            // spam
            this.tabList[3].items = await this.getDataTranslation(mapData.filter(x => x.status === 3), true)
            this.tabList[3].count = mapData.filter(x => x.status === 3).length
            this.totalRows = mapData.filter(x => x.status === 3).length
          } else {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: 'Opps! Something wrong',
                icon: 'AlertOctagonIcon',
                variant: 'danger',
                text: String(this.showError(res.data.data.error, ',')),
              },
            })
          }
        }
      } catch (error) {
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Opps! Something wrong',
            icon: 'AlertOctagonIcon',
            variant: 'danger',
            text: String(error),
          },
        })
      }
    },
    async getDataDetail(id, i) {
      this.getActive(i)
      const siteId = JSON.parse(localStorage.getItem('siteID')).id
      try {
        const res = await Request.get(
          this.$http,
          `${process.env.VUE_APP_API_URL}/comment/${id}?site_id=${siteId}`,
        )
        if (res.status === 200) {
          if (res.data.status) {
            this.model.id_code = res.data.data.id_code
            this.model.fullname = res.data.data.fullname
            this.model.avatar_customers = res.data.data.avatar_customers
            this.model.rating = res.data.data.rating
            this.model.created_at = res.data.data.created_at
            this.model.order_id = res.data.data.order_item_id
            this.model.order_item_id_code = res.data.data.order_id_code
            this.model.product_name = res.data.data.name
            this.model.avatar_product = res.data.data.warehouse_avatar
            this.model.content = res.data.data.content
            this.model.feedback = res.data.data.feedback
            this.model.status = res.data.data.status
          } else {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: 'Opps! Something wrong',
                icon: 'AlertOctagonIcon',
                variant: 'danger',
                text: String(this.showError(res.data.data.error, ',')),
              },
            })
          }
        }
      } catch (error) {
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Opps! Something wrong',
            icon: 'AlertOctagonIcon',
            variant: 'danger',
            text: String(error),
          },
        })
      }
    },
    async handleUpdate(id, stt) {
      try {
        const params = {
          site_id: JSON.parse(localStorage.getItem('siteID')).id,
          status: String(stt),
        }
        const res = await Request.put(
          this.$http,
          `${process.env.VUE_APP_API_URL}/comment/${id}`,
          params,
        )
        if (res.status === 200) {
          if (res.data.status) {
            this.$swal({
              icon: 'success',
              title: 'Updated!',
              text: 'Comment has been updates.',
              customClass: {
                confirmButton: 'btn btn-success',
              },
            })
            this.loadList()
          } else {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: 'Opps! Something wrong',
                icon: 'AlertOctagonIcon',
                variant: 'danger',
                text: String(this.showError(res.data.error, ',')),
              },
            })
          }
        }
      } catch (error) {
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Opps! Something wrong',
            icon: 'AlertOctagonIcon',
            variant: 'danger',
            text: String(error),
          },
        })
      }
    },
    async handleUpdateContent(id) {
      try {
        const params = {
          site_id: JSON.parse(localStorage.getItem('siteID')).id,
          content: this.model.content,
        }
        const res = await Request.put(
          this.$http,
          `${process.env.VUE_APP_API_URL}/comment/${id}`,
          params,
        )
        if (res.status === 200) {
          if (res.data.status) {
            this.$swal({
              icon: 'success',
              title: 'Updated!',
              text: 'Comment has been updates.',
              customClass: {
                confirmButton: 'btn btn-success',
              },
            })
            this.loadList()
          } else {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: 'Opps! Something wrong',
                icon: 'AlertOctagonIcon',
                variant: 'danger',
                text: String(this.showError(res.data.error, ',')),
              },
            })
          }
        }
      } catch (error) {
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Opps! Something wrong',
            icon: 'AlertOctagonIcon',
            variant: 'danger',
            text: String(error),
          },
        })
      }
    },
    async handleUpdateContentFeedback(id) {
      try {
        const params = {
          site_id: JSON.parse(localStorage.getItem('siteID')).id,
          feedback: this.model.feedback,
        }
        const res = await Request.put(
          this.$http,
          `${process.env.VUE_APP_API_URL}/comment/${id}`,
          params,
        )
        if (res.status === 200) {
          if (res.data.status) {
            this.$swal({
              icon: 'success',
              title: 'Updated!',
              text: 'Comment has been updates.',
              customClass: {
                confirmButton: 'btn btn-success',
              },
            })
            this.loadList()
          } else {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: 'Opps! Something wrong',
                icon: 'AlertOctagonIcon',
                variant: 'danger',
                text: String(this.showError(res.data.error, ',')),
              },
            })
          }
        }
      } catch (error) {
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Opps! Something wrong',
            icon: 'AlertOctagonIcon',
            variant: 'danger',
            text: String(error),
          },
        })
      }
    },
    async handlerDelete(id) {
      this.$swal({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, delete it!',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(async result => {
        if (result.value) {
          try {
            const siteId = JSON.parse(localStorage.getItem('siteID')).id
            const res = await Request.delete(
              this.$http,
              `${process.env.VUE_APP_API_URL}/comment/${id}?site_id=${siteId}`,
            )
            if (res.status === 200) {
              if (res.data.status) {
                this.$swal({
                  icon: 'success',
                  title: 'Deleted!',
                  text: 'Comment has been deleted.',
                  customClass: {
                    confirmButton: 'btn btn-success',
                  },
                })
                this.loadList()
              } else {
                this.$toast({
                  component: ToastificationContent,
                  position: 'top-right',
                  props: {
                    title: 'Opps! Something wrong',
                    icon: 'AlertOctagonIcon',
                    variant: 'danger',
                    text: String(this.showError(res.data.error, ',')),
                  },
                })
              }
            }
          } catch (error) {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: 'Opps! Something wrong',
                icon: 'AlertOctagonIcon',
                variant: 'danger',
                text: String(error),
              },
            })
          }
        }
      })
    },
    previewFiles(input) {
      let i = 0
      // this.list_thumbnail = []
      if (input.target.files.length + this.model.list_thumbnail.length <= 12) {
        // eslint-disable-next-line no-plusplus
        for (i; i < input.target.files.length; i++) {
          const file = input.target.files[i]
          const reader = new FileReader()
          // eslint-disable-next-line no-loop-func
          if (i > 0) {
            reader.onload = e => {
              this.model.list_thumbnail.push(e.target.result)
            }
          } else {
            reader.onload = e => {
              if (this.model.avatar !== null) {
                this.model.list_thumbnail.push(e.target.result)
              } else {
                this.model.avatar = e.target.result
              }
            }
          }
          reader.onerror = error => {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: 'Opps! Something wrong',
                icon: 'AlertOctagonIcon',
                variant: 'danger',
                text: String(error),
              },
            })
          }
          reader.readAsDataURL(file)
        }
      } else {
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Opps! Something wrong',
            icon: 'AlertOctagonIcon',
            variant: 'danger',
            text: String('Maximum is 12 picture'),
          },
        })
      }
    },
    delImg(index) {
      this.model.list_thumbnail.splice(index, 1)
    },
    async loadLanguages() {
      this.languageList = await this.loadLanguage()
    },
    commentsCount(type) {
      let result = 0

      if (type === 'all') {
        result = this.items.length
      } else {
        result = this.items.filter(item => item.status === type).length
      }
      return result
    },
  },
}
</script>
<style lang="scss">
.custom-good-table .vgt-table {
  font-size: 14px;

  thead th {
    vertical-align: middle;
    text-align: center;
    padding-right: 0.75em;
    white-space: nowrap;
  }
  td {
    vertical-align: middle;
  }
}
.custom-good-table-ctrl-border{
  .vgt-table{
    border: 1px solid #fff !important;
  }
  td{
    padding: 0.75rem !important;

    &:first-child {
      padding-left: 1.5rem !important;
    }
    &:last-child {
      padding-right: 1.5rem !important;
    }
  }
  thead{
    background-color: #f3f2f7
  }
}
</style>
<style lang="sass">
.justify-space-evenly
  justify-content: space-evenly
#modal-detail-comment
  .modal
    &-avatar
      width: 100px
      height: 100px
    &-name
      width: 500px
    &-img-product
      width: 150px
      height: 150px
      object-fit: contain
    &-comments
      width: 1100px
      margin: 0 auto
      margin-top: 25px
      padding: 1rem
      background: #f3f3f3
    &-gallery
      display: grid
      grid-template-columns: repeat(5, 1fr)
      grid-gap: 0 15px
      img
        margin-top: 20px
        width: 200px
        height: 200px
    &-controls
      display: flex
      gap: 0 40px
      cursor: pointer
      .control-item
        position: relative
        &:not(:last-child):after
          position: absolute
          content: "|"
          right: -20px
          top: 0
        &:hover
          color: var(--primary)
    &-upload-img
      gap: 0 20px
    &-upload
      width: 150px
      height: 150px
      display: flex
      justify-content: center
      align-items: center
      border: 1px dashed var(--primary)
      border-radius: 4px
      cursor: pointer
    &-list
      position: relative
      width: 150px
      &:hover
        &-delete
          visibility: visible
      &-delete
        // visibility: hidden
        position: absolute
        top: 20px
        right: 20px
        transform: translate(50%, -50%)
        z-index: 10
        padding: 10px
    &-list-thumbnail
      width: 100%

.filter
  &-tab
    display: flex
    overflow: hidden
    flex: none
    border-bottom: 1px solid #e5e5e5

    &__item
      min-width: 64px
      padding: 0 16px
      height: 56px
      display: flex
      align-items: center
      font-weight: 500
      font-size: 14px
      border-bottom: 3px solid transparent
      cursor: pointer
      transition: all .2s

      &.active,
      &:hover
        color: var(--primary)

      &.active
        border-bottom-color: var(--primary)
</style>
